<template>
  <f7-page class="countryview-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader || isCountryFetching" @infinite="loadMore($refs)">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_SHOP')" type="back" :search="false" :cart="false" />
    </template>

    <section class="countryview-container">
      <div class="country-banner" :style="{ 'background-image': 'url(' + require('@/assets/images/nature-bg.jpg') + ')' }">
        <div class="country-texts">
          <h1 class="country-name">
            {{ country.CountryName }}
          </h1>
          <span class="countryshop-now">{{ $t.getTranslation("LBL_SHOP_NOW") }}</span>
        </div>
      </div>

      <template v-if="RegionList.length">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_REGIONS") }}</h1>
        </div>

        <div class="country-tabs">
          <f7-swiper :slides-per-view="'auto'" :space-between="10">
            <f7-swiper-slide v-for="region in RegionList" :key="'region_' + region.RegionId" style="width: auto !important" @click="selectRegion(region)">
              <div class="category" :class="{ active: RegionId == region.RegionId }">
                <div class="category-item auto-widht-swuper-select">{{ region.RegionName }}</div>
              </div>
            </f7-swiper-slide>
          </f7-swiper>
        </div>
      </template>

      <template v-if="RegionId && SubRegionList.length">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_SUB_REGION") }}</h1>
        </div>

        <div class="country-tabs">
          <f7-swiper :slides-per-view="'auto'" :space-between="10">
            <f7-swiper-slide v-for="sub in SubRegionList" :key="'subregion_' + sub.SubRegionId" style="width: auto !important" @click="selectSubRegion(sub)">
              <div class="category" :class="{ active: SubRegionId == sub.SubRegionId }">
                <div class="category-item auto-widht-swuper-select">{{ sub.SubRegionName }}</div>
              </div>
            </f7-swiper-slide>
          </f7-swiper>
        </div>
      </template>

      <div class="title">
        <h1>{{ $t.getTranslation("LBL_CATEGORIES") }}</h1>
      </div>
      <div class="country-tabs">
        <f7-swiper :slides-per-view="'auto'" :space-between="10">
          <f7-swiper-slide v-for="category in categoryList" :key="'cat_' + category.CategoryId" style="width: auto !important" @click="updateCategory(category.CategoryCode, $refs)">
            <div class="category" :class="{ active: categoryCode == category.CategoryCode }">
              <div class="category-item">{{ category.CategoryName }}</div>
            </div>
          </f7-swiper-slide>
        </f7-swiper>

        <div class="product-filter">
          <div class="total-product">{{ totalReturn }} {{ $t.getTranslation("LBL_TOTAL_PRODUCTS") }}</div>
          <div class="filter-controls">
            <!-- <f7-link @click="showFilterPopUp"><font-awesome-icon :icon="['far', 'filter']" fixed-width/></f7-link>
            <f7-link @click="showSort()">
              <font-awesome-icon v-if="sort == 'DESC'" :icon="['far', 'arrow-up']" fixed-width />
              <font-awesome-icon v-else :icon="['far', 'arrow-down']" fixed-width />
            </f7-link> -->
          </div>
        </div>

        <f7-tabs>
          <f7-tab id="one" class="page-content merchant-home-tab" tab-active>
            <div class="container">
              <ProductListComponent
                v-if="countryCode"
                ref="productListComponent"
                :isLoader="true"
                :returnDataCount="returnDataCount"
                :countryCode="countryCode"
                :options="true"
                :hideCategory="true"
                :isCountryFilter="false"
                :showNotFoundComponent="true"
              />
            </div>
          </f7-tab>
          <f7-tab id="two" class="page-content merchant-product-tab"> </f7-tab>
          <f7-tab id="three" class="page-content"> </f7-tab>
        </f7-tabs>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent, computed } from "vue";
import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import ProductListComponent from "@/components/ProductListComponent.vue";

// import CountryProductFilterPopUp from "@/components/CountryProductFilterPopUp.vue";
// import ProductListSortComponent from "@/components/ProductListSortComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));
const ProductListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ "@/components/ProductListComponent.vue"));

export default defineComponent({
  name: "CountryViewPage",
  components: {
    ProductNavigationComponent,
    ProductListComponent,
  },
  props: {
    f7router: Object,
    f7route: Object,
    title: { type: String, default: "" },
    supplierCode: { type: String, default: "" },
    mode: { type: String, default: "" },
    options: Boolean,
    size: { type: Number, default: 16 },
  },
  setup(props) {
    const store = useStore();
    const $t = inject("$translation");

    const userData = computed(() => store.getters["user/getData"]);
    const pageFilterData = computed(() => store.getters["page/getFilterData"]);

    const productListComponent = ref("productListComponent");
    const showPreloader = ref(false);

    let page = 1;
    let lastPage = 1;

    const isFetching = ref(false);
    const isCountryFetching = ref(true);

    const countryCode = ref(props?.f7route?.params?.code);
    const totalReturn = ref(0);
    const categoryList = ref([]);

    const country = ref({});
    let categoryCode = ref("");

    let RegionList = ref([]);
    let RegionId = ref("");

    let SubRegionId = ref("");
    let SubRegionList = ref([]);

    const getCategoryList = async () => {
      let ret = await get("/category/list", { LanguageCode: $t.getLanguage(), page: 1, size: 1000, mode: "SORTFEATURED" });
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          categoryList.value.push(item);
        }
        categoryList.value.unshift({ CategoryName: $t.getTranslation("LBL_ALL"), CategoryId: 0, Type: "PRODUCT", CategoryCode: "", FieldName: null, FileName: null, Position: null, StatusCode: "ACTIVE" });
      }
    };

    const getRegion = async () => {
      isCountryFetching.value = true;
      let ret = await get("/public/product/region", { LanguageCode: $t.getLanguage(), CountryCode: countryCode.value });

      RegionList.value = ret?.List || [];
      RegionList.value.unshift({ RegionName: $t.getTranslation("LBL_ALL"), RegionId: "" });
      isCountryFetching.value = false;
    };

    const getCountry = async () => {
      let ret = await get("/country/list", { LanguageCode: $t.getLanguage(), page: 1, size: 1000, CountryCode: countryCode.value, mode: "ALL" });
      if (ret && ret.data && Array.isArray(ret.data)) {
        country.value = ret.data[0];
      }
    };

    const updateCategory = async (category, $refs) => {
      categoryCode.value = category;
      $refs.productListComponent.updateCategory({ CategoryCode: category, CountryCode: countryCode.value });
    };

    const returnDataCount = (count) => {
      totalReturn.value = count;
    };

    const loadMore = ($refs) => {
      showPreloader.value = true;
      $refs.productListComponent.loadMore();
      showPreloader.value = false;
    };

    onMounted(() => {
      getCategoryList();
      getCountry();
      getRegion();

      if (props?.f7route?.params?.code == userData.value?.Country?.CountryCode) {
        //if the same then pass the region id and sub region id..
        RegionId.value = userData.value?.Country?.RegionId;
        SubRegionId.value = userData.value?.Country?.SubRegionId;

        if (userData.value?.Country?.SubRegionId) {
          getSubRegion(RegionId.value);
        }
      }

      if (pageFilterData.value?.CategoryCode && pageFilterData.value?.CategoryCode != "" && pageFilterData.value.CountryCode == countryCode.value) {
        categoryCode.value = pageFilterData.value.CategoryCode;
      }
    });

    const selectSubRegion = (SubRegion) => {
      SubRegionId.value = SubRegion.SubRegionId;
      store.dispatch("user/setData", {
        Country: {
          SubRegionId: SubRegionId.value,
          RegionId: RegionId.value,
          CountryCode: countryCode.value,
        },
      });
      productListComponent.value.region(RegionId.value, SubRegionId.value);
    };

    const getSubRegion = async (RegionId) => {
      isCountryFetching.value = true;
      let ret = await get("/public/product/sub/region", { LanguageCode: $t.getLanguage(), RegionId });
      isCountryFetching.value = false;

      SubRegionList.value = ret?.List || [];

      if (SubRegionList.value.length) {
        SubRegionList.value.unshift({ SubRegionName: $t.getTranslation("LBL_ALL"), SubRegionId: "" });
      }
    };

    const selectRegion = (Region) => {
      RegionId.value = Region.RegionId;
      if (Region.RegionId) {
        getSubRegion(Region.RegionId);
      } else {
        SubRegionId.value = "";
        SubRegionList.value = "";
      }
      store.dispatch("user/setData", {
        Country: {
          SubRegionId: SubRegionId.value,
          RegionId: RegionId.value,
          CountryCode: countryCode.value,
        },
      });
      productListComponent.value.region(RegionId.value, SubRegionId.value);
    };

    return {
      isCountryFetching,
      RegionList,
      RegionId,
      SubRegionId,
      SubRegionList,
      store,
      selectSubRegion,
      selectRegion,
      isFetching,
      loadMore,
      productListComponent,
      showPreloader,
      categoryList,
      updateCategory,
      totalReturn,
      country,
      categoryCode,
      returnDataCount,
      countryCode,
    };
  },
});
</script>

<style scoped>
.category.active {
  background: #0c4d8f;
  border-radius: 15px;
  color: #fff;
}

.countryview-container {
  margin: 10px 0px;
  /*width: calc(100% - 30px);*/
}

.category-item {
  text-align: center;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-item.active {
  background: #0c4d8f;
  color: #fff;
}

.category {
  display: flex;
  cursor: pointer;
}
.countryview-page {
  padding: 20px;
}
.country-banner {
  width: 100%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 0px 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  overflow: hidden;
  cursor: pointer;
}
.country-texts {
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: 20px;
}
.country-name {
  font-size: 50px;
  font-weight: 600;
}
.countryshop-now {
  font-weight: 600;
}
.product-container {
  margin-top: 10px;
}
.product-filter {
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
.product-filter > .link {
  color: black;
}
.total-product {
  font-weight: 600;
  text-transform: uppercase;
}
.auto-widht-swuper-select {
  width: auto;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
